// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-js": () => import("/tmp/build_e4eaaf4f_/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-embed-player-js": () => import("/tmp/build_e4eaaf4f_/src/templates/embedPlayer.js" /* webpackChunkName: "component---src-templates-embed-player-js" */),
  "component---src-pages-404-js": () => import("/tmp/build_e4eaaf4f_/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/tmp/build_e4eaaf4f_/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("/tmp/build_e4eaaf4f_/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

